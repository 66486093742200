import { createApp } from 'vue';
import App from './App.vue';
import './assets/css/index.scss';
import VueGtag from 'vue-gtag';
import './utils/gtm';

createApp(App)
  .use(VueGtag, {
    config: { id: 'G-BTXT0HLH21' },
  })
  .mount('#app');
