<template>
    <section class="footer">
    <div class="footer-main flex flex-align-items-center">
      <div class="container">
        <div class="footer-inner hide-mobile">
          <div class="footer-left-side">
            <img src="../assets/icons/footer-logo.svg" alt="Adaptive" class="footer-logo">
            <div class="footer-copyright ml-24"> © 2022 Zerek.ai Ltd.</div>
          </div>
          <div class="footer-navigation">
            <div class="footer-copyright right">
              55/21 Mangilik El, Esil District, Z05T3E5 Nur-Sultan, Kazakhstan
            </div>
          </div>
          <div class="footer-navigation">
            <a href="https://github.com/zerek-ai-ltd" target="_blank" @click="goTo('github')">GitHub</a>
          </div>
        </div>
        <div class="hide-tablet hide-desktop">
          <div class="flex flex-row flex-justify-content-space-between">
            <div class="footer-left-side">
              <img src="../assets/icons/footer-logo.svg" alt="Adaptive" class="footer-logo">
            </div>
            <div class="footer-navigation">
              <a href="https://github.com/zerek-ai-ltd" target="_blank">GitHub</a>
            </div>
          </div>
          <div class="flex flex-column">
            <div class="footer-copyright mt-12">
              55/21 Mangilik El, Esil District, Z05T3E5 Nur-Sultan, Kazakhstan
            </div>
            <div class="footer-copyright mt-8">© 2022 Zerek.ai Ltd.</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {event} from "vue-gtag";

export default {
  name: 'Footer',
  methods: {
      goTo(value) {
        event('btn_' + value, {method: 'Google', item: 'navigate_to'});
      }
  }
}
</script>
