<template>
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" >
            <div class="modal-close-btn">
                <img @click="$emit('close')" src="../assets/icons/modal-close.png" />
            </div>
            <div class="modal-body" v-if="form.initialized">
                <h2 class="modal-header">Request Demo</h2>
<!--                <p class="modal-text">Leave your contact details, we will schedule a meeting</p>-->

                <form @submit.prevent="onSubmit()">
                    <div class="modal-form-input">
                        <input type="text" name="name" id="name" v-model.trim="fields.name" required autocomplete="off">
                        <label for="name">Your name</label>
                    </div>
                    <div class="modal-form-input">
                        <input type="text" name="company" id="company" v-model.trim="fields.companyName" required autocomplete="off">
                        <label for="company">Company Name</label>
                    </div>
                    <div class="modal-form-input">
                        <input type="text" name="email" id="email" v-model.trim="fields.email" required autocomplete="off">
                        <label for="email">Your email</label>
                    </div>
                  <div class="modal-form-input">
                      <p>How did you heard about us?</p>
                      <p class="modal-form-input-radio">
                          <input type="radio" id="test1" name="radio-group" v-model="fields.navigateFrom" value="Linked.in" checked>
                          <label for="test1">Linked.in</label>
                      </p>
                      <p class="modal-form-input-radio">
                        <input type="radio" id="test2" name="radio-group" v-model="fields.navigateFrom" value="Google/Search engine">
                        <label for="test2">Google/Search engine</label>
                      </p>
                      <p class="modal-form-input-radio">
                        <input type="radio" id="test3" name="radio-group" v-model="fields.navigateFrom"
                               value="Recommended by a friend or a colleague">
                        <label for="test3">Recommended by a friend or a colleague</label>
                      </p>
                      <p class="modal-form-input-radio">
                        <input type="radio" id="test4" name="radio-group" v-model="fields.navigateFrom" value="Other">
                        <label for="test4">Other</label>
                      </p>
                  </div>
                  <div class="modal-form-input" v-if="fields.navigateFrom === 'Other'">
                    <input type="other" name="other" id="other"
                           placeholder="Other" v-model.trim="fields.navigateDetails" autocomplete="off">
                  </div>

                  <button type="submit" class="modal-form-button" v-bind:class="{'submit': isSubmit}">
                    <span v-if="!isSubmit">SEND</span>
                  </button>
                </form>
            </div>
            <div class="modal-body" v-if="form.submitted">
                <div class="modal-icon">
                    <img src="../assets/icons/modal-success.svg" alt="success!">
                </div>
                <p class="modal-text">We will contact you to make an appointment very soon</p>
                <button class="modal-form-button" @click="$emit('close')">EXIT</button>
            </div>
            <div class="modal-body" v-if="form.error">
                <h2 class="modal-header t-center">Error!</h2>
                <p class="modal-text">{{errorMessage}}</p>
                <button class="modal-form-button" @click="$emit('close')">Go to main</button>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    data() {
        return {
            form: {
                initialized: true,
                submitted: false,
                error: false
            },
            fields: {
                name: '',
                companyName: '',
                email: '',
                navigateFrom: 'Linked.in',
                navigateDetails: ''
            },
            errorMessage: '',
            isSubmit: false
        }
    },
    methods: {
        async onSubmit() {
            this.isSubmit = true;
            const api_key ='keyX5cjRgblQHszsY';

            if (this.fields.navigateFrom === 'Other') {
              this.fields.navigateFrom = this.fields.navigateDetails;
            }
            const requestBody = {
                records: [
                    {
                        fields: {
                            "company_name": this.fields.companyName,
                            "name": this.fields.name,
                            "email": this.fields.email,
                            "source": this.fields.navigateFrom,
                            "status": "new"
                        }
                    }
                ]
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${api_key}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody)
            }
            const res = await fetch("https://api.airtable.com/v0/app7yorxfCMmzYTBb/V4Requests", requestOptions);
            const data = await res.json();
            if (res.status === 200) {
                this.form.initialized = false;
                this.form.submitted = true;
                this.isSubmit = false;
            }
            else {
                this.errorMessage = data.error.message;
                this.form.initialized = false;
                this.form.error = true;
                this.isSubmit = false;
            }
        }
    }
}
</script>
