<template>
    <div class="container-fluid container-sticky"
         :class="{'scroll': isScroll, 'static': !isScroll, 'fixed': !isMobile}"
         id="header">
      <div v-if="dropdownMenu" class="dropdown-nav">
          <div class="dropdown-nav-close-holder">
            <img @click="showMenu()" src="../assets/icons/modal-close.png" />
          </div>
          <span @click="showMenu('features')"><a href="#benefits">Features</a></span>
          <span @click="showMenu('how_works')"><a href="#platform">How It Works?</a></span>
          <span @click="showMenu('use_cases')"><a href="#use-cases">Use Cases</a></span>
          <span @click="showMenu('modal')"><a>Request Demo</a></span>
        </div>
      <div class="container">
        <div class="nav">
          <img :src="getImagePath(logoUrl)" alt="zerek_logo">
          <div class="nav-right-section">
            <span class="hide-mobile">
              <a href="#benefits">
               Features
              </a>
            </span>
            <span class="hide-mobile">
              <a href="#platform">
                How It Works?
              </a>
            </span>
            <span class="hide-mobile">
              <a href="#use-cases">
                Use Cases
              </a>
            </span>
          </div>
          <div class="nav-right-section">
            <span class="">
              <div class="nav-right-section-button flex flex-align-items-center"
                   @click="showMenu('modal')">
                <a>Request Demo</a>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
</template>

<transition name="modal">
<Modal v-if="showModal" @close="toggleModal()"></Modal>
</transition>

<script>

import { event } from 'vue-gtag';

export default {
    name: 'Header',
    data() {
        return {
            dropdownMenu: false,
            isScroll: false,
            isMobile: false,
            logoUrl: 'logo-light.svg',
            menuIconUrl: 'burger-light.svg'
        }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll, true);
      if (window.screen.width < 600) {
        this.isMobile = true;
      }
    },
    unmounted() {
       window.removeEventListener('scroll', this.handleScroll, true);
    },
    methods: {
        showMenu(value) {
          if (value === 'modal') {
            this.$emit('clicked');
            event('request_demo_from_header', {method: 'Google', item: 'contact_us'});
          } else {
            this.dropdownMenu = !this.dropdownMenu;
            document.body.style.overflow = this.dropdownMenu ? "hidden" : "auto";
            event('btn_' + value, {method: 'Google', item: 'navigate_to'});

          }
        },
        handleScroll() {
          if (window.scrollY > 0) {
            this.isScroll = true;
            this.logoUrl = 'logo-dark.svg';
            this.menuIconUrl = 'burger-dark.svg';
          } else {
            this.isScroll = false;
            this.logoUrl = 'logo-light.svg';
            this.menuIconUrl = 'burger-light.svg';
          }
        },
        getImagePath(filename) {
          return require('../assets/icons/' + filename);
        }
    }
}
</script>
